import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Homepage from "./pages/homepage/Homepage";
import Account from "./pages/account/Account";
import Products from "./pages/products/Products";
import Product from "./pages/product/Product";
// import HelpCenter from "./pages/help-center/HelpCenter";
// import Policies from "./pages/policies/Policies";
// import Guide from "./pages/guide/Guide";
// import NotFound from "./pages/not-found/NotFound";
import Contact from "./pages/contact/Contact";
import { StyledApp } from "./styled/StyledApp";
import ScrollToTop from "./hooks/ScrollToTop";
import Brand from "./pages/brand/Brand";
import Brands from "./pages/brands/Brands";
import Configuration from "./Configuration";
// import MyWearables from "./pages/dashboard/wearables/MyWearables";
import ManOfTheMatch from "./pages/man-of-the-match/ManOfTheMatch";
import ManOfTheMatchSubmitted from "./pages/man-of-the-match-submitted/ManOfTheMatchSubmitted";
import BusinessCardMocked from "./pages/business-card-mocked/BusinessCardMocked";
import BusinessCard from "./pages/business-card/BusinessCard";
import AscotFamilyDay from "./pages/ascot-family-day/AscotFamilyDay";
import Registration from "./pages/ascot-family-day/Registration";
import Location from "./pages/ascot-family-day/Location";
import Feedback from "./pages/ascot-family-day/Feedback";
import Progress from "./pages/ascot-family-day/Progress";
import CagedSteelPromo from "./pages/caged-steel-promo/CagedSteelPromo";
import FeedbackForm from "./pages/caged-steel-promo/FeedbackForm";
import CampaignManager from "./pages/campaign-manager/CampaignManager";
import EventManager from "./pages/event-manager/EventManager";
import DevicesManager from "./pages/campaign-manager/DevicesManager";
import SearchDevices from "./pages/campaign-manager/SearchDevices";
import Campaign from "./pages/campaign-manager/Campaign";
import CampaignLinks from "./pages/campaign-manager/components/CampaignLinks";
import VotingResults from "./pages/campaign-manager/VotingResults";
import ScavengerHunt from "./pages/campaign-manager/ScavengerHunt";
import CoachTrackerCampaign from "./pages/campaign-manager/CoachTrackerCampaign";
import ScavengerDeliverPrizes from "./pages/campaign-manager/ScavengerDeliverPrizes";
import BalanceAndPin from "./pages/balance-and-pin/BalanceAndPin";
import { AscotFontStyle } from "./pages/ascot-family-day/styled/SharedStyles";
import { MuchBetterFontStyle } from "./pages/balance-and-pin-mb-christmas/styled/BalanceAndPinMuchBetterChristmasStyles";
import BalanceAndPinMuchBetterChristmas from "./pages/balance-and-pin-mb-christmas/BalanceAndPinMuchBetterChristmas";
import MasterCardDemo from "./pages/mastercard-demo/MasterCardDemo";
import ElectricalSafety from "./pages/electrical-safety/ElectricalSafety";
import CreateCampaign from "./pages/campaign-manager/CreateCampaign";
import EditCampaign from "./pages/campaign-manager/EditCampaign";
import CampaignDevices from "./pages/campaign-manager/CampaignDevices";
import PromoReadyAdmin from "./pages/campaign-manager/PromoReadyAdmin";
import TransferDevices from "./pages/campaign-manager/TransferDevices";
import CreateDevice from "./pages/campaign-manager/CreateDevice";
import EditDevice from "./pages/campaign-manager/EditDevice";
import DeviceLinks from "./pages/campaign-manager/components/DeviceLinks";
import LeoSchachter from "./pages/leo-schachter/LeoSchachter";
import Event from "./pages/event-manager/Event";
import RolandGarros from "./pages/roland-garros/RolandGarros";
import UefaPromoReady from "./pages/uefa/UefaPromoReady";
import UefaPitchesOfHope from "./pages/uefa/UefaPitchesOfHope";
import ScsPromoReady from "./pages/scs-promo-ready/ScsPromoReady";
import SodexoPromoReady from "./pages/sodexo-promo-ready/SodexoPromoReady";
import FossilProductInformation from "./pages/fosil-promo-ready/FossilProductInformation";
import FossilVote from "./pages/fosil-promo-ready/FossilVote";
import BelfastPromoReady from "./pages/belfast-giants-promo/BelfastGiantsPromo";
import HugoBoss from "./promo-ready/hugo-boss/HugoBoss";
import Tap2Start from "./promo-ready/tap2start/Tap2Start";
import Tap2StartTap2 from "./promo-ready/tap2start-tap2/Tap2StartTap2";
import Tap2StartSmartex from "./promo-ready/tap2start-smartex/Tap2StartSmartex";
import Tap2StartScs from "./promo-ready/tap2start-scs/Tap2StartScs";
import { Tap2FontStyle } from "./promo-ready/tap2start-tap2/styles/SharedStyles";
import RugbyWcCapgemini from "./promo-ready/rugby-wc-capgemeni/RugbyWcCapgemini";
import RugbyWcAsahi from "./promo-ready/rugby-wc-asahi/RugbyWcAsahi";
import RugbyWcLandRover from "./promo-ready/rugby-wc-landrover/RugbyWcLandRover";
import ChesterRacecourse from "./promo-ready/chester-racecourse/ChesterRacecourse";
import AfterEvent from "./pages/caged-steel-promo/AfterEvent";
import Infineon from "./promo-ready/infineon/Infineon";
import HugoBossExperience from "./promo-ready/hugo-boss-experience/HugoBossExperience";
import BluewaterPromoReady from "./promo-ready/bluewater/Bluewater";
import LostModePage from "./promo-ready/lost-mode/LostModePage";
import IceMuchbetter from "./promo-ready/ice-muchbetter/IceMuchbetter";
import Pay360 from "./promo-ready/pay-360/Pay360";
import Planica24 from "./promo-ready/planica24/Planica24";
import Road2Milan from "./promo-ready/road2milan/Road2Milan";
import Freemans from "./promo-ready/freemans/Freemans";
import Pepcity from "./promo-ready/pepcity/Pepcity";
import { PepsicoFontStyle } from "./promo-ready/pepcity/styles/Pepcity";
import Uefa24 from "./promo-ready/uefa24/Uefa24";
import { MasterCardDemoFonts } from "./pages/mastercard-demo/styles/MasterCardDemoStyles";
import RolandGarros24 from "./promo-ready/roland-garros24/RolandGarros24";
import CoachTrackerLogin from "./pages/coach-tracker-login/CoachTrackerLogin";
import CoachTracker from "./pages/coach-tracker/CoachTracker";
import Money2020 from "./promo-ready/money-2020/Money2020";
import TasteOfDublin from "./promo-ready/taste-of-dublin/TasteOfDublin";
import CocaCola2024 from "./promo-ready/coca-cola-2024/CocaCola2024";
import { CocaColaFontStyle } from "./promo-ready/coca-cola-2024/styles";
import TourDeFrance from "./promo-ready/tour-de-france-24/TourDeFrance";
import BritishGas from "./promo-ready/british-gas-24/BritishGas";
import { BritishGasFontStyle } from "./promo-ready/british-gas-24/styles/BritishGas";
import CampBestival24 from "./promo-ready/camp-bestival-24/CampBestival24";
import Callback3ds from "./promo-ready/callback-3ds/Callback3ds";
import ArosaClassicCar from "./promo-ready/arosa-classic-car/ArosaClassicCar";
import TencoBobo from "./promo-ready/tenco-bobo/TencoBobo";
import Tap2Promo from "./promo-ready/tap2-promo/Tap2Promo";
import Faqs from "./pages/faqs/Faqs";

function App() {
  return (
    <>
      <Router>
        <Configuration />
        <ScrollToTop />
        <AscotFontStyle />
        <MuchBetterFontStyle />
        <MasterCardDemoFonts />
        <Tap2FontStyle />
        <PepsicoFontStyle />
        <BritishGasFontStyle />
        <CocaColaFontStyle />

        <StyledApp>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/products" element={<Products />} />
            <Route
              path="/products/:brand/:model/:version"
              element={<Product />}
            />
            <Route path="/brands" element={<Brands />} />
            <Route path="/brands/:brand" element={<Brand />} />
            {/* <Route path="/help" element={<HelpCenter />} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/account" element={<Account />} />
            <Route path="coach-tracker-login" element={<CoachTrackerLogin />} />
            <Route path="coach-tracker" element={<CoachTracker />} />

            {/* <Route path="/policies" element={<Policies />} />z
            <Route path="/guide" element={<Guide />} /> */}
            {/* <Route path="/wearables" element={<MyWearables />} /> */}
            <Route path="/business-card/:id" element={<BusinessCardMocked />} />
            <Route path="/business-card/" element={<BusinessCard />} />
            <Route path="/campaign-manager" element={<CampaignManager />} />
            <Route path="/event-manager" element={<EventManager />} />
            <Route path="/devices-manager" element={<DevicesManager />} />
            <Route path="/devices-manager/search" element={<SearchDevices />} />
            <Route path="/devices-manager/create" element={<CreateDevice />} />
            <Route
              path="/devices-manager/:serial/edit"
              element={<EditDevice />}
            />
            <Route
              path="/devices-manager/:serial/links"
              element={<DeviceLinks />}
            />
            <Route path="/das-admin" element={<PromoReadyAdmin />} />
            <Route
              path="/campaign-manager/new-campaign"
              element={<CreateCampaign />}
            />
            <Route
              path="/campaign-manager/:campaignId/edit"
              element={<EditCampaign />}
            />
            <Route
              path="/campaign-manager/:campaignId/transfer"
              element={<TransferDevices />}
            />
            <Route
              path="/campaign-manager/:campaignId/links"
              element={<CampaignLinks />}
            />
            <Route
              path="/campaign-manager/:campaignId/devices"
              element={<CampaignDevices />}
            />
            <Route
              path="/campaign-manager/:campaignId/voting-results"
              element={<VotingResults />}
            />
            <Route
              path="/campaign-manager/:campaignId/scavenger-hunt"
              element={<ScavengerHunt />}
            />
            <Route
              path="/campaign-manager/:campaignId/coach-tracker"
              element={<CoachTrackerCampaign />}
            />
            <Route
              path="/campaign-manager/:campaignId/scavenger-hunt/deliver-prizes"
              element={<ScavengerDeliverPrizes />}
            />
            <Route
              path="/campaign-manager/:campaignId"
              element={<Campaign />}
            />
            <Route path="/event-manager/:eventId" element={<Event />} />
            <Route path="/caged-steel-promo/" element={<CagedSteelPromo />} />
            <Route path="/roland-garros/" element={<RolandGarros />} />
            <Route path="/rg24/" element={<RolandGarros24 />} />
            <Route path="/uefa/" element={<UefaPromoReady />} />
            <Route path="/uefa24/" element={<Uefa24 />} />
            <Route
              path="/uefa-pitches-of-hope/"
              element={<UefaPitchesOfHope />}
            />
            <Route path="/scs/" element={<ScsPromoReady />} />
            <Route path="/sodexo/" element={<SodexoPromoReady />} />
            <Route
              path="/caged-steel-promo/:serial/feedback"
              element={<FeedbackForm />}
            />
            <Route
              path="/ascot-family-day/:serial"
              element={<AscotFamilyDay />}
            />
            <Route
              path="/ascot-family-day/:serial/registration"
              element={<Registration />}
            />
            <Route
              path="/ascot-family-day/:serial/:location"
              element={<Location />}
            />
            <Route
              path="/ascot-family-day/:serial/feedback"
              element={<Feedback />}
            />
            <Route
              path="/ascot-family-day/:serial/progress"
              element={<Progress />}
            />

            <Route
              path="/real-betis-vs-celta-vigo-mom"
              element={<ManOfTheMatch />}
            />
            <Route
              path="/nomination-submitted"
              element={<ManOfTheMatchSubmitted />}
            />
            <Route
              path="/anonymous-prepaid-balance-and-pin"
              element={<BalanceAndPin />}
            />
            <Route path="/digiseq-happy-holidays" element={<BalanceAndPin />} />
            <Route
              path="/much-better-happy-holidays"
              element={<BalanceAndPinMuchBetterChristmas />}
            />
            <Route path="/mastercard" element={<MasterCardDemo />} />
            <Route path="/electrical-safety" element={<ElectricalSafety />} />
            <Route path="/leo-schachter" element={<LeoSchachter />} />
            <Route
              path="/fossil-promo-ready/product/"
              element={<FossilProductInformation />}
            />
            <Route path="/fossil-promo-ready/vote/" element={<FossilVote />} />
            <Route path="/belfast-giants" element={<BelfastPromoReady />} />
            <Route path="/hugo-boss-promo-ready" element={<HugoBoss />} />
            <Route path="/tap2start" element={<Tap2Start />} />
            <Route path="/tap2start/tap2" element={<Tap2StartTap2 />} />
            <Route path="/tap2start/smartex" element={<Tap2StartSmartex />} />
            <Route path="/tap2start/scs" element={<Tap2StartScs />} />
            <Route path="/rugbywc-capgemini" element={<RugbyWcCapgemini />} />
            <Route path="/rugbywc-asahi" element={<RugbyWcAsahi />} />
            <Route path="/rugbywc-landrover" element={<RugbyWcLandRover />} />
            <Route path="/chester-racecourse" element={<ChesterRacecourse />} />
            <Route path="/cs-after-event" element={<AfterEvent />} />
            <Route path="/infineon-promo" element={<Infineon />} />
            <Route
              path="/hugo-boss-experience"
              element={<HugoBossExperience />}
            />
            <Route path="/lost-mode" element={<LostModePage />} />
            <Route path="/ice-muchbetter" element={<IceMuchbetter />} />
            <Route path="/bluewater" element={<BluewaterPromoReady />} />
            <Route path="/pay360" element={<Pay360 />} />
            <Route path="/planica24" element={<Planica24 />} />
            <Route path="/road2milan" element={<Road2Milan />} />
            <Route path="/freemans" element={<Freemans />} />
            <Route path="/pepcity" element={<Pepcity />} />
            <Route path="/money-2020" element={<Money2020 />} />
            <Route path="/taste-of-dublin" element={<TasteOfDublin />} />
            <Route path="/coca-cola-2024" element={<CocaCola2024 />} />
            <Route path="/tour-de-france-24" element={<TourDeFrance />} />
            <Route path="/british-gas-24" element={<BritishGas />} />
            <Route path="/camp-bestival-24" element={<CampBestival24 />} />
            <Route path="/3ds-callback" element={<Callback3ds />} />
            <Route path="/arosa-classic-car" element={<ArosaClassicCar />} />
            <Route path="/tenco-bobo" element={<TencoBobo />} />
            <Route path="/tap2promo" element={<Tap2Promo />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="*" element={<Homepage />} />
          </Routes>
        </StyledApp>
      </Router>
    </>
  );
}

export default App;
