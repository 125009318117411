import React from "react";

import { useEffect } from "react";

const Faqs = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//eu.fw-cdn.com/12857626/826527.js";
    script.async = true;
    script.setAttribute("chat", "true");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return <div>Faqs Page</div>;
};

export default Faqs;
